<div *ngIf="structureLoading()" class="loading-message">
  Structure view loading ...
</div>

<div class="pdb-structure-view">
  <div *ngFor="let dn of [displayName]" class="pdb-iframe-container">
    <iframe (load)="finishedLoading()" [src]="sanitizedURL" scrolling="no" #pdbiframe class="embedded-pdb">
    </iframe>
  </div>

  <div *ngIf="!structureLoading()" class="pdb-text">
    <div class="pdb-main-text">
      Protein structures from PDB<span *ngIf="displayName"> for {{displayName}}</span>:
    </div>

    <div class="pdb-table-container pdb-table-container-{{pageType}}">
      <table>
        <thead>
          <tr>
            <th>PDB ID</th>
            <th>Method</th>
            <th>Res (Å)</th>
            <th *ngIf="pageType == 'gene'">Chain</th>
            <th *ngIf="pageType == 'gene'">Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pdbEntry of displayEntries"
              [popover]="entryPopover" [delay]="500"
              [popoverContext]="popoverContext(pdbEntry)" [adaptivePosition]="false"
              container="body" placement="bottom" triggers="mouseenter:mouseleave"
              [ngClass]="{'selected-entry': currentEntry && currentEntry.pdb_id == pdbEntry.pdb_id }"
              (click)="selectEntry(pdbEntry)">
            <td class="pdb-entry-detail">
              {{pdbEntry.pdb_id}}
            </td>
            <td class="pdb-entry-detail">
              {{pdbEntry.experimental_method}}
            </td>
            <td class="pdb-entry-detail">
              {{displayResolution(pdbEntry)}}
            </td>
            <td *ngIf="pageType == 'gene'" class="pdb-entry-detail">
              {{pdbEntry.chain}}
            </td>
            <td *ngIf="pageType == 'gene'" class="pdb-entry-detail">
              {{pdbEntry.position}}
            </td>
            <td class="pdb-entry-detail">
              <a [attr.href]="appConfig.pdbe_entry_url + '/' + pdbEntry.pdb_id"
                 [title]="'Visit the PDBe page for ' + pdbEntry.pdb_id">PDBe</a> |
              <a [attr.href]="appConfig.rcsb_pdb_entry_url + '/' + pdbEntry.pdb_id"
                [title]="'Visit the RCSB PDB page for ' + pdbEntry.pdb_id">RCSB</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pdb-current-entry-details">
      <div *ngIf="pageType == 'gene' && currentEntry && currentEntry.reference">
        <app-reference-short
          [reference]="currentEntry.reference"
          [linkText]="'View publication for this structure: ' + currentEntry.reference.authors_abbrev"></app-reference-short>
      </div>
      <div *ngIf="pageType == 'reference' && currentEntry">
        Proteins in this structure:
        <span *ngFor="let geneChain of currentEntry.gene_chains" class="gene-chain-gene">
          <app-gene-link [gene]="geneChain.gene" [long]="false"></app-gene-link>
        </span>
      </div>
    </div>
  </div>
</div>

<ng-template #entryPopover let-title="title" let-authors="authors" let-pdbId="pdbId">
  <div *ngIf="pageType == 'gene'">
    <div class="entry-popover-title">{{title}}</div>
    <div class="entry-popover-authors">{{authors}}</div>
  </div>

  <div>(Click to view this structure)</div>

  <div class="entry-popover-image">
    <img src="{{popoverImageUrl(pdbId)}}"/>
  </div>
</ng-template>
