import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.css'],
    standalone: false
})
export class MessageDialogComponent implements OnInit {
  public title: string;
  public message: string;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
}
