<fieldset *ngIf="annotationTable && annotationTable.length > 0" class="annotation-table">
  <legend>
    <span *ngIf="annotationTypeName">{{tableDisplayName}}</span>
    <span *ngIf="!annotationTypeName">Annotations</span>

    <app-help-link *ngIf="typeConfig && typeConfig.help_route" [route]="typeConfig.help_route"
                   [title]="helpIconTitle">
    </app-help-link>
  </legend>

  <div class="annotation-table-container">
  <app-deletion-viability-summary *ngIf="geneDetails && annotationTypeName == 'single_locus_phenotype'"
                                  [geneDetails]="geneDetails">
  </app-deletion-viability-summary>

    <div class="annotation-table-slim" *ngIf="geneDetails && typeConfig.slim_subset_name">
      <app-slim-summary [geneDetails]="geneDetails" [annotationTypeName]="annotationTypeName">
      </app-slim-summary>
    </div>

  <div class="gene-alleles-link"
       *ngIf="geneDetails && typeConfig.feature_type && typeConfig.feature_type == 'genotype'">
    <a routerLink="/gene_alleles/{{geneDetails.uniquename}}">View all alleles of <span class="name-id-highlight">{{geneDetails.displayName}}</span></a>
  </div>

  <div *ngIf="sanitizedFeatureViewURL">
    <div class="view-all-link">
      <span class="help-icon">
        <app-help-link *ngIf="typeConfig && typeConfig.help_route" [route]="typeConfig.help_route"
          [title]="'Click to view documentation for this section'">
        </app-help-link>
      </span>

      <a routerLink="/gene_protein_features/{{geneDetails!.uniquename}}">View all protein features ...</a>
    </div>

    <div *ngFor="let gene of [geneDetails]">
    <iframe [src]="getProteinViewerIFrameURL()" id="protein-feature-viewer-mods" frameborder="0" scrolling="no"
            onload="resizeIframe(this, 175)" class="embedded-protein-viewer" #modsproteinfeaturesiframe>
    </iframe>
    </div>
  </div>

  <div *ngIf="split_by_parents.length == 0"
    intersectionObserver
    (intersection)="intersectHandler(annotationTypeName, $event.intersect)">
    <app-annotation-sub-table
       [annotationTypeName]="annotationTypeName"
       [scope]="scope"
       [hideColumns]="hideColumns"
       [geneDetails]="geneDetails"
       [featureInFirstColumn]="featureInFirstColumn"
       [annotationTable]="annotationTable">
    </app-annotation-sub-table>
  </div>
  <div *ngIf="split_by_parents.length != 0">
    <div *ngFor="let conf of split_by_parents; let i = index">
      <div *ngIf="splitDataList[conf.config_name] && splitDataList[conf.config_name].length > 0"
          intersectionObserver
          (intersection)="intersectHandler(annotationTypeName + '-' + conf.config_name, $event.intersect)">
        <div *ngIf="conf.display_name" [attr.id]="annotationTypeName + '-' + conf.config_name"
             class="annotation-sub-table-name">{{capitalize(conf.display_name)}}</div>
        <app-annotation-sub-table
           [annotationTypeName]="annotationTypeName"
           [scope]="scope"
           [hideColumns]="hideColumns"
           [geneDetails]="geneDetails"
           [featureInFirstColumn]="featureInFirstColumn"
           [annotationTable]="splitDataList[conf.config_name]">
        </app-annotation-sub-table>
      </div>
    </div>
  </div>

  <app-protein-properties *ngIf="geneDetails && geneDetails.transcripts[0]?.protein && annotationTypeName == 'pombase_family_or_domain'"
                          [geneDetails]="geneDetails">
  </app-protein-properties>
  </div>

</fieldset>
