<div *ngIf="hasGoCam">
  <div class="full-view-header">
    GO-CAM model
    <span *ngIf="gocams.length == 1">{{currentGoCamId}}</span>
    <select *ngIf="gocams.length > 1" [(ngModel)]="currentGoCamId" (ngModelChange)="modelChange()" class="go-cam-select">
      <option *ngFor="let gocam of gocams" [ngValue]="gocam.gocam_id">
        {{gocam.title || gocam.gocam_id}}
      </option>
    </select>
    - <a routerLink="{{largeViewPath}}">larger view ...</a>
    <span class="help-link"><a routerLink="/documentation/go-cam-pathway-models">Documentation <img class="help-icon" src="/assets/info_icon.svg"/></a></span>
  </div>

  <div *ngFor="let o of [geneOrTermDetails]">
  <iframe [src]="getIFrameURL()" class="embedded-go-cam-viewer" #gocamiframe>
  </iframe>
  </div>
</div>

<div *ngIf="!hasGoCam && displayName" class="no-go-cams">
  No GO-CAM models available for {{displayName}}
</div>
