<div *ngIf="proteinTooLong()" class="alphafold-too-long-message">
  This protein has no AlphaFold predicted structure because it is longer than
  {{appConfig.alphafoldMaxProteinLength}} residues. See the
  <a href="https://alphafold.ebi.ac.uk/faq">AlphaFold FAQ</a> for more details.
</div>

<div *ngIf="!proteinTooLong() && alphaFoldLoading()" class="alphafold-loading-message">
  Structure view loading ...
</div>

<div *ngIf="!proteinTooLong()" class="alphafold-container">
  <div *ngFor="let gene of [geneDetails]" class="alphafold-iframe-container">
    <iframe (load)="alphaFoldFinishedLoading()" #alphafoldiframe scrolling="no" class="embedded-alphafold">
    </iframe>
  </div>

  <div *ngIf="!alphaFoldLoading()" class="alphafold-text">
    <div>
      <a href="https://alphafold.ebi.ac.uk/entry/{{geneDetails.uniprot_identifier}}" target="_blank">
        View full AlphaFold page
      </a>
    </div>
    <div>
      <a href="https://molstar.org/viewer-docs/#mouse-controls" target="_blank">
        Viewer control documentation
      </a>
    </div>
    <div class="structure-confidence-help">
      <div class="structure-confidence-help-header">Model Confidence:</div>
      <div class="structure-confidence-help-item">
        <span class="structure-confidence-help-colour" style="background-color: rgb(0, 83, 214);">&nbsp;</span>
        <span>Very high</span>
      </div>
      <div class="structure-confidence-help-item">
        <span class="structure-confidence-help-colour" style="background-color: rgb(101, 203, 243);">&nbsp;</span>
        <span>Confident</span>
      </div>
      <div class="structure-confidence-help-item">
        <span class="structure-confidence-help-colour" style="background-color: rgb(255, 219, 19);">&nbsp;</span>
        <span>Low</span>
      </div>
      <div class="structure-confidence-help-item">
        <span class="structure-confidence-help-colour" style="background-color: rgb(255, 125, 69);">&nbsp;</span>
        <span>Very low</span>
      </div>
    </div>
  </div>
</div>
