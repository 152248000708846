import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-recent-news',
    templateUrl: './recent-news.component.html',
    styleUrls: ['./recent-news.component.css'],
    standalone: false
})
export class RecentNewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
