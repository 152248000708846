<div *ngIf="geneDetails">

<!--  <a routerLink="/gene/{{geneDetails.uniquename}}">&lt;- Back to gene page</a> -->

  <div class="header details-page-name-and-id">
    <span>
    <span class="details-page-name-and-id-prefix">Protein features: </span>

    <a routerLink="/gene/{{geneDetails.uniquename}}">
      <span *ngIf="geneDetails.name">
        <span class="name-id"><span class="name-id-highlight">{{geneDetails.name}}</span> / {{geneDetails.uniquename}}</span>
      </span>
      <span class="gene-name" *ngIf="!geneDetails.name">
        <span class="name-id">{{geneDetails.uniquename}}</span>
      </span>
    </a>
    </span>

    <span class="help-link">
      <app-help-link [route]="'/documentation/gene-page-protein-features-widget'"
                     [title]="'Click to view documentation for the protein feature viewer'">
        Documentation ...
      </app-help-link>
    </span>

  </div>

  <div class="table-toggle" *ngIf="!deployConfigService.productionMode()">
    <a *ngIf="!featureTableVisible" (click)="featureTableVisible = true">
      Show as table
    </a>
    <a *ngIf="featureTableVisible" (click)="featureTableVisible = false">
      Return to diagram
    </a>
  </div>

  <div *ngIf="!featureTableVisible">
  <div *ngIf="getIFrameURL()">
    <div *ngFor="let gene of [geneDetails]">
    <iframe [src]="getIFrameURL()"
            frameborder="0" scrolling="no" onload="resizeIframe(this, 400)"
            id="protein-feature-viewer-full"
            class="full-protein-viewer" #proteinfeaturesiframe>
    </iframe>
    </div>
  </div>
  </div>

  <div class="feature-table"
       *ngIf="featureTableVisible && !deployConfigService.productionMode()">
    <app-protein-feature-table [geneDetails]="geneDetails">
    </app-protein-feature-table>
  </div>
</div>
