(() => {
  "use strict";

  var e = {
      593: (e, t, r) => {
        r.d(t, {
          Z: () => s
        });
        var n = r(81),
          i = r.n(n),
          a = r(645),
          o = r.n(a)()(i());
        o.push([e.id, "rhea-reaction {\n    display: flex;\n    flex-direction: column;\n    min-width: 100%;\n    justify-content: flex-start;\n    align-items: flex-start;\n    align-content: stretch;\n}\n\nrhea-reaction img {\n    width: 200px;\n    height: 200px;\n}\n\nrhea-reaction .rhea-reaction-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n}\n\nrhea-reaction-block {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    padding: 10px;\n}\n\nrhea-reaction-block.smallMolecule:hover {\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n\nrhea-reaction .residues {\n    display: flex;\n    flex-direction: row;\n    border: 1px grey dashed;\n    justify-content: center;\n}\n\nrhea-reaction-block .name {\n    margin-bottom: 10px;\n}\n\nrhea-reaction-block.and,\nrhea-reaction-block.none,\nrhea-reaction-block.forward,\nrhea-reaction-block.backward,\nrhea-reaction-block.bi,\nrhea-reaction-block.coefficient {\n    justify-content: center;\n}\n\nrhea-reaction .participantfooter {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\nrhea-reaction .filler {\n    flex-grow: 1;\n}", ""]);
        const s = o;
      },
      645: e => {
        e.exports = function (e) {
          var t = [];
          return t.toString = function () {
            return this.map(function (t) {
              var r = "",
                n = void 0 !== t[5];
              return t[4] && (r += "@supports (".concat(t[4], ") {")), t[2] && (r += "@media ".concat(t[2], " {")), n && (r += "@layer".concat(t[5].length > 0 ? " ".concat(t[5]) : "", " {")), r += e(t), n && (r += "}"), t[2] && (r += "}"), t[4] && (r += "}"), r;
            }).join("");
          }, t.i = function (e, r, n, i, a) {
            "string" == typeof e && (e = [[null, e, void 0]]);
            var o = {};
            if (n) for (var s = 0; s < this.length; s++) {
              var c = this[s][0];
              null != c && (o[c] = !0);
            }
            for (var l = 0; l < e.length; l++) {
              var h = [].concat(e[l]);
              n && o[h[0]] || (void 0 !== a && (void 0 === h[5] || (h[1] = "@layer".concat(h[5].length > 0 ? " ".concat(h[5]) : "", " {").concat(h[1], "}")), h[5] = a), r && (h[2] ? (h[1] = "@media ".concat(h[2], " {").concat(h[1], "}"), h[2] = r) : h[2] = r), i && (h[4] ? (h[1] = "@supports (".concat(h[4], ") {").concat(h[1], "}"), h[4] = i) : h[4] = "".concat(i)), t.push(h));
            }
          }, t;
        };
      },
      81: e => {
        e.exports = function (e) {
          return e[1];
        };
      },
      379: e => {
        var t = [];
        function r(e) {
          for (var r = -1, n = 0; n < t.length; n++) if (t[n].identifier === e) {
            r = n;
            break;
          }
          return r;
        }
        function n(e, n) {
          for (var a = {}, o = [], s = 0; s < e.length; s++) {
            var c = e[s],
              l = n.base ? c[0] + n.base : c[0],
              h = a[l] || 0,
              u = "".concat(l, " ").concat(h);
            a[l] = h + 1;
            var d = r(u),
              p = {
                css: c[1],
                media: c[2],
                sourceMap: c[3],
                supports: c[4],
                layer: c[5]
              };
            if (-1 !== d) t[d].references++, t[d].updater(p);else {
              var m = i(p, n);
              n.byIndex = s, t.splice(s, 0, {
                identifier: u,
                updater: m,
                references: 1
              });
            }
            o.push(u);
          }
          return o;
        }
        function i(e, t) {
          var r = t.domAPI(t);
          return r.update(e), function (t) {
            if (t) {
              if (t.css === e.css && t.media === e.media && t.sourceMap === e.sourceMap && t.supports === e.supports && t.layer === e.layer) return;
              r.update(e = t);
            } else r.remove();
          };
        }
        e.exports = function (e, i) {
          var a = n(e = e || [], i = i || {});
          return function (e) {
            e = e || [];
            for (var o = 0; o < a.length; o++) {
              var s = r(a[o]);
              t[s].references--;
            }
            for (var c = n(e, i), l = 0; l < a.length; l++) {
              var h = r(a[l]);
              0 === t[h].references && (t[h].updater(), t.splice(h, 1));
            }
            a = c;
          };
        };
      },
      569: e => {
        var t = {};
        e.exports = function (e, r) {
          var n = function (e) {
            if (void 0 === t[e]) {
              var r = document.querySelector(e);
              if (window.HTMLIFrameElement && r instanceof window.HTMLIFrameElement) try {
                r = r.contentDocument.head;
              } catch (e) {
                r = null;
              }
              t[e] = r;
            }
            return t[e];
          }(e);
          if (!n) throw new Error("Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid.");
          n.appendChild(r);
        };
      },
      216: e => {
        e.exports = function (e) {
          var t = document.createElement("style");
          return e.setAttributes(t, e.attributes), e.insert(t, e.options), t;
        };
      },
      565: (e, t, r) => {
        e.exports = function (e) {
          var t = r.nc;
          t && e.setAttribute("nonce", t);
        };
      },
      795: e => {
        e.exports = function (e) {
          var t = e.insertStyleElement(e);
          return {
            update: function (r) {
              !function (e, t, r) {
                var n = "";
                r.supports && (n += "@supports (".concat(r.supports, ") {")), r.media && (n += "@media ".concat(r.media, " {"));
                var i = void 0 !== r.layer;
                i && (n += "@layer".concat(r.layer.length > 0 ? " ".concat(r.layer) : "", " {")), n += r.css, i && (n += "}"), r.media && (n += "}"), r.supports && (n += "}");
                var a = r.sourceMap;
                a && "undefined" != typeof btoa && (n += "\n/*# sourceMappingURL=data:application/json;base64,".concat(btoa(unescape(encodeURIComponent(JSON.stringify(a)))), " */")), t.styleTagTransform(n, e, t.options);
              }(t, e, r);
            },
            remove: function () {
              !function (e) {
                if (null === e.parentNode) return !1;
                e.parentNode.removeChild(e);
              }(t);
            }
          };
        };
      },
      589: e => {
        e.exports = function (e, t) {
          if (t.styleSheet) t.styleSheet.cssText = e;else {
            for (; t.firstChild;) t.removeChild(t.firstChild);
            t.appendChild(document.createTextNode(e));
          }
        };
      }
    },
    t = {};
  function r(n) {
    var i = t[n];
    if (void 0 !== i) return i.exports;
    var a = t[n] = {
      id: n,
      exports: {}
    };
    return e[n](a, a.exports, r), a.exports;
  }
  r.n = e => {
    var t = e && e.__esModule ? () => e.default : () => e;
    return r.d(t, {
      a: t
    }), t;
  }, r.d = (e, t) => {
    for (var n in t) r.o(t, n) && !r.o(e, n) && Object.defineProperty(e, n, {
      enumerable: !0,
      get: t[n]
    });
  }, r.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t), (() => {
    var e = r(379),
      t = r.n(e),
      n = r(795),
      i = r.n(n),
      a = r(569),
      o = r.n(a),
      s = r(565),
      c = r.n(s),
      l = r(216),
      h = r.n(l),
      u = r(589),
      d = r.n(u),
      p = r(593),
      m = {};
    m.styleTagTransform = d(), m.setAttributes = c(), m.insert = o().bind(null, "head"), m.domAPI = i(), m.insertStyleElement = h(), t()(p.Z, m), p.Z && p.Z.locals && p.Z.locals;
    const f = {
        forward: "=>",
        backward: "<=",
        bi: "<=>",
        none: "="
      },
      b = {
        SMALL_MOLECULE: "smallMolecule",
        GENERIC_COMPOUND: "genericcomp",
        POLYMER: "polymer"
      };
    class g extends HTMLElement {
      constructor() {
        super(), this.rheaURL = "https://www.rhea-db.org", this.imageHost = this.hasAttribute("imagehost") ? this.getAttribute("imagehost") : this.rheaURL, this._reaction = {}, this._rheaid = "";
      }
      get reaction() {
        return this._reaction;
      }
      set reaction(e) {
        0 == e.length ? console.log("nothing to render") : (this._reaction = e, this.render(e));
      }
      set rheaid(e) {
        this._rheaid = e, this.renderWithId(e);
      }
      get rheaid() {
        return this._rheaid;
      }
      attributeChangedCallback(e, t, r) {
        if (t !== r) switch (e) {
          case "rheaid":
            let e = r.toLowerCase();
            e.startsWith("rhea:") && (e = e.subString(5)), this.renderWithId(e);
            break;
          case "usehost":
            this.rheaURL = r;
            break;
          case "imagehost":
            this.imageHost = r;
        }
      }
      static get observedAttributes() {
        return ["rheaid", "usehost", "imagehost"];
      }
      async renderWithId(e) {
        this.hasAttribute("usehost") && (this.rheaURL = this.getAttribute("usehost"));
        let t = await fetch((r = e, n = this.rheaURL, `${n}/rhea/${r}/json`));
        var r, n;
        t = await t.json(), this.reaction = t;
      }
      async render(e) {
        this._rheaid = e.id;
        let t = {
          nosource: this.hasAttribute("nosource"),
          showids: this.hasAttribute("showids"),
          formula: this.hasAttribute("formula"),
          charge: this.hasAttribute("charge"),
          smiles: this.hasAttribute("smiles"),
          inchikey: this.hasAttribute("inchikey"),
          structuresearch: this.hasAttribute("structuresearch"),
          rheaurl: this.rheaURL,
          imagehost: this.imageHost
        };
        var r;
        this.innerHTML = `\n            ${t.nosource ? "" : `<div class="rhea-reaction-source">\n                    <a href="${r = e.id, `https://www.rhea-db.org/reaction?id=${r}`}">${t.showids ? `RHEA:${e.id}` : "Source: Rhea"}</a> \n                </div>`}\n            <div class="rhea-reaction-container">\n                ${this.getList("left", e.left)}\n                    <rhea-reaction-block class="${e.direction}">${f[e.direction]}</rhea-reaction-block>\n                ${this.getList("right", e.right)}\n            </div>`, this.querySelectorAll("rhea-reaction-block.participant").forEach(r => {
          let n = r.getAttribute("index").split("-"),
            i = n[0];
          n = n[1], r.removeAttribute("index"), r.setParticipant(e[i][n], this.hasAttribute("zoom"), t);
        }), this.hasAttribute("zoom") && Array.from(this.querySelectorAll(".zoomLink")).forEach(e => e.onclick = t => {
          t.stopPropagation(), t.preventDefault();
          let r = new CustomEvent("zoomClicked", {
            detail: {
              chebi: "chebi:" + t.target.getAttribute("data-chebi-id"),
              imgURL: t.target.getAttribute("data-chebi-zoom")
            },
            bubbles: !0
          });
          e.dispatchEvent(r);
        });
        let n = new CustomEvent("rendered", {
          detail: {},
          bubbles: !0
        });
        this.dispatchEvent(n);
      }
      getList(e, t) {
        return t.map((r, n) => {
          let i = n != t.length - 1;
          return `\n                ${r.coff ? `<rhea-reaction-block class="coefficient" >${r.coff}</rhea-reaction-block>` : ""}\n                <rhea-reaction-block class="participant ${b[r.type]}" index="${e}-${n}"></rhea-reaction-block>\n                ${i ? '<rhea-reaction-block class="and">+</rhea-reaction-block>' : ""}`;
        }).map(e => e.trim()).join("");
      }
    }
    class v extends HTMLElement {
      constructor() {
        super();
      }
      smallMolecule(e, t, r = "", n) {
        let i = e.htmlname ? e.htmlname : e.label,
          a = e.htmlname ? `<div class="name">\n                <a href="${o = e.id, `https://www.ebi.ac.uk/chebi/searchId.do?chebiId=CHEBI:${o}`}">${decodeURI(i)}</a>\n                ${e.location ? `(${e.location})` : ""}\n            </div>` : "";
        var o;
        let s = n.structuresearch && e.smiles,
          c = e.position ? `<div class="position">Position: ${e.position}</div>` : "",
          l = "" !== r ? `<a class="participantId">${r}</a>` : "",
          h = n.polymer ? n.polymer.thumbnail : e.thumbnail;
        h = h.startsWith("http") ? h : `${n.imagehost}${h}`;
        let u = n.polymer ? n.polymer.zoomed : e.zoomed;
        u = u.startsWith("http") ? u : `${n.imagehost}${u}`;
        let d = "";
        return d = n.polymer ? `<span>${n.polymer.htmlname}</span> <img width="200px" height="200px" alt="No structure information present" src="${h}"></img> ${a} ${l} ${c}` : n.generic ? `<img width="200px" height="200px" alt="No structure information present" src="${h}"></img> ${a} ${l} ${c}` : `${a} ${l} <img width="200px" height="200px" alt="No structure information present" src="${h}"></img> ${c}`, d + '<span class="filler"></span>' + `<div class="participantfooter">\n        ${(n.formula && e.formula ? `<span class="info"><i>Formula</i>: ${e.formula}</span>` : "") + (n.charge && e.charge ? `<span class="info"><i>Charge</i>: ${e.charge}</span>` : "") + (n.inchikey && e.inchikey ? `<span class="info"><i>InchiKey</i>: ${e.inchikey}</span>` : "") + (t ? `<a class="zoomLink" data-chebi-id="${e.id}" data-chebi-zoom="${u}">zoom</a>` : "") + (s ? `<a class="structuresearch" href="${n.rheaurl}/structuresearch/?query=${e.id}">structure search` : "")}</div>`;
      }
      setParticipant(e, t, r) {
        switch (e.type) {
          case "SMALL_MOLECULE":
            this.innerHTML = this.smallMolecule(e, t, r.showids ? `CHEBI:${e.id}` : "", r);
            break;
          case "POLYMER":
            let n = {
              ...r,
              polymer: {
                thumbnail: e.thumbnail,
                zoomed: e.zoomed
              }
            };
            (e.htmlname || e.label) && (n.polymer.htmlname = e.htmlname ? e.htmlname : e.label), this.innerHTML = this.smallMolecule(e.underlyingChebi, t, r.showids ? `RHEA-COMP:${e.id}` : "", n);
            break;
          case "GENERIC_COMPOUND":
            this.innerHTML = `\n                <div class="name">\n                    <a class="generic">${decodeURI(e.htmlname)}</a>\n                </div>\n                ${r.showids ? `<a class="participantId"> RHEA-COMP:${e.id}</a>` : ""}\n                <div class="residues">${e.reactivePart.map((e, t) => `<rhea-reaction-block class="participant ${b[e.type]}" index="${t}"></rhea-reaction-block>`).join("")}</div>`, this.querySelectorAll("rhea-reaction-block").forEach(n => {
              let i = n.getAttribute("index");
              n.removeAttribute("index");
              let a = {
                ...r,
                generic: {}
              };
              n.setParticipant(e.reactivePart[i], t, a);
            });
            break;
          default:
            return "";
        }
      }
    }
    window.customElements.get("rhea-reaction-block") || window.customElements.define("rhea-reaction-block", v), window.customElements.get("rhea-reaction") || window.customElements.define("rhea-reaction", g);
  })();
})();